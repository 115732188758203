<template>
    <!--    <div class="bg-4">-->
    <a :href="link" class="bg-4 text-decoration-none" target="_blank">

        <!--            <router-link class="min-w-40" target="_blank" :to="{path:link, params:{next:nextLink,prev:prevLink}}"><img :src="img" alt=""-->
        <!--                                                                                                       class="img-fluid">-->
        <!--            </router-link>-->

        <a :href="link" class="mb-0 bg-4" target="_blank"
           :to="{path:link, params:{next:nextLink,prev:prevLink}}">
            <div class="card-min-h">
                <img :src="img" alt="" class="img-fluid mb-0">
            </div>
        </a>
        <short-section :heading="heading" :source="source" :author="author" :size="size">
            <template #content><p class="fs--2"> {{ content }} </p></template>
        </short-section>

    </a>
</template>
<script> import ShortSection from './ShortSection';

export default {
    name: 'ArticleCard',
    components: { ShortSection },
    props: {
        heading: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: Image
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    }
}; </script>
<style lang="scss" scoped>
.card-min-h{
    position: relative;
    height: 14rem;
    img{
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
