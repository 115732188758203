<template>
    <div>
        <section>
            <InnerBanner image="bg-contact" class="contact-banner" textColor="white"/>
        </section>
        <section class="py-5 py-lg-6">
            <div class="container">
                <div class="row fl-x-cc">
                    <div class="col-lg-6 mb-5 mb-lg-0">
                        <h4 class="font-weight-bold mb-4  text-left text-lg-center">REACH OUT</h4>
                        <p class="mb-4  text-left text-lg-center">Pallichira Medlounges Healthcare Pvt. Ltd,<br> Perumthuruthy PO ,
                            Ezhinijillam,
                            (Opposite HP Petrol Pump) Thiruvalla, Pathanamthitta,
                            Kerala Pin 689107</p>
                        <transition name="fade" mode="out-in">
                            <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-if="state === 'S'" key="'S">
                                <div class="text-success">
                                    <div class="text-center mb-4">
                                        <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                                    </div>
                                    <div class="text-center">
                                        <p class="fs-4">Thank you <strong>{{ name }}</strong> for Contacting us us</p>
                                        We have received your request and will respond to you within 24 hours. For
                                        urgent enquiries please call us on the telephone numbers provided.
                                    </div>
                                </div>
                            </div>

                            <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'F'"
                                 key="'F">
                                <div class="text-danger">
                                    <div class="text-center mb-4">
                                        <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                                    </div>
                                    <div class="text-center">
                                        <p class="fs-4"><strong>OOPS!</strong></p>
                                        Something has gone wrong, Please try again.
                                    </div>
                                </div>
                            </div>
                            <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'L'"
                                 key="'L">
                                <div class="text-center p-4">
                                    <loading-animation/>
                                    <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                        server</p>
                                </div>
                            </div>
                            <s-form class="contact-form row" v-else role="form" @submit="onSubmit"
                                    autocomplete="off">
                                <div class="col-12">
                                    <validated-input name="Name" v-model="name" shadow="0" placeholder=""
                                                     label="Name(required)" :rules="rules.first_name" required/>
                                </div>
                                <div class="col-12">
                                    <validated-input name="Phone Number" v-model="phone" shadow="0" placeholder=""
                                                     label="Phone Number(required)" :rules="rules.phone_number"
                                                     required/>
                                </div>
                                <div class="col-12">
                                    <validated-input type="email" name="Email" v-model="email" shadow="0"
                                                     placeholder=""
                                                     label="Email(required)" :rules="rules.email_id"/>
                                </div>
                                <div class="col-12">
                                    <validated-text-area label="Message" v-model="message" :rules="rules.message1"
                                                         shadow="0"/>
                                </div>
                                <div class="col-12 mt-4 text-left text-lg-center">
                                    <btn design="animated-e" border-radius="0" color="primary" class="c-btn-1"
                                         type="submit" text="Submit"></btn>
                                </div>
                            </s-form>
                        </transition>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import InnerBanner from '../views/components/InnerBanner';
import axios from 'axios';
import LoadingAnimation from './components/LoadingAnimation';

export default {
    name: 'Contact',
    components: {
        InnerBanner,
        LoadingAnimation
    },
    metaInfo: {
        title: 'AESTHETIC PHYSICIAN | BODY POSITIVE ENTHUSIAST | ENTREPRENEUR',
        titleTemplate: '%s - Dr. Joseph Thomas',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Dr. Joseph Thomas is India\'s leading aesthetic physician and a leading healthcare entrepreneur from Kerala. He is the Medical Director and Founder of Medlounges.'
            },
            {
                name: 'keywords',
                content: 'Aesthetic Physician , Cosmetologist, Kerala best cosmetologist , Cosmetologist doctor,Cosmetologist Ernakulam'
            }
        ]
    },
    data () {
        return {
            state: 'D',
            name: '',
            phone: '',
            email: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('email', this.email);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://reache.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style scoped>

</style>
