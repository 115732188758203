<template>
    <div class="bannner-h bg-home-banner">
        <div class="container">
            <div class="row">
                <div class="col-md-6 content ">
                    <h1 class="font-weight-bold text-banner text-uppercase">
                        Aesthetic Physician <br>
                        Body Positive Enthusiast<br>
                        Entrepreneur<br>
                        Philanthropist<br>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Banner'
};
</script>

<style scoped lang="scss">
.bannner-h {
    min-height: 90vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    background-position: left;
    color: white;
    //-webkit-animation: zoomin 10s ease-in infinite;
    //animation: zoomin 10s ease-in infinite;
    //transition: all .5s ease-in-out;
    //overflow: hidden;

    .text-banner {
        color: white;
        line-height: 60px;
    }

    @media (max-width: 1024px) {
        background-position: 51.5%;
        color: black;
        .text-banner {
            background: rgba(0, 0, 0, 0.45);
            padding: 10px;
            line-height: 40px;
        }
    }
    @media (max-width: 768px) {
        align-items: flex-end;
        padding-bottom: 60px;
    }

    @media (max-width: 552px) {
        h1 {
            font-size: 1.7rem;
        }
    }

    @media (max-width: 375px) {
        h1 {
            font-size: 1.4rem;
        }
    }

    //.content {
    //    margin-top: 87px;
    //}
}

//
///* Zoom in Keyframes */
//@-webkit-keyframes zoomin {
//    0% {
//        transform: scale(1);
//    }
//    50% {
//        transform: scale(1.1);
//    }
//    100% {
//        transform: scale(1);
//    }
//}
//
//@keyframes zoomin {
//    0% {
//        transform: scale(1);
//    }
//    50% {
//        transform: scale(1.1);
//    }
//    100% {
//        transform: scale(1);
//    }
//}
//
///*End of Zoom in Keyframes */
//
///* Zoom out Keyframes */
//@-webkit-keyframes zoomout {
//    0% {
//        transform: scale(1);
//    }
//    50% {
//        transform: scale(0.67);
//    }
//    100% {
//        transform: scale(1);
//    }
//}
//
//@keyframes zoomout {
//    0% {
//        transform: scale(1);
//    }
//    50% {
//        transform: scale(0.67);
//    }
//    100% {
//        transform: scale(1);
//    }
//}

/*End of Zoom out Keyframes */

</style>
