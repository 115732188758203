<template>
    <div class="about">
        <section>
            <InnerBanner image="bg-covid" class="covid-banner"/>
        </section>

        <section class="py-5 py-lg-7">
            <div class="container">
                <div class="row text-center text-lg-justify">
                    <div class="col-lg-12">
                        <div class="pl-lg-5">
                            <h1 class="font-weight-bold mb-4 ">Initiatives to combat coronavirus (COVID-19)</h1>
                            <p>The dreadful impact of the pandemic is not unknown to all of us. But also what great time
                                than this to give back to society? Dr Joseph with his active participation in numerous
                                volunteering works has helped thousands of people affected by COVID-19. He has been at
                                the forefront in helping food banks and donating PPE and cleaning supplies to medical
                                centres. Be it through financial support, medical assistance, or other charitable works,
                                <a class="text-decoration-none" href="https://drjosephthomas.in/">Dr Joseph</a>
                                despite his busy schedule has never ceased a chance when it comes to serving
                                his community.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        <section class="bg-quote-home bg-quote-3">-->
        <!--            <div class="container">-->
        <!--                <div class="row quote-content">-->
        <!--                    <div class="col-lg-8 text-center">-->
        <!--                        <h2 class="font-weight-bold mb-0 text-white">-->
        <!--                            STRENGTH INDIA MOVEMENT IS RAPIDLY GROWING WITH OVER 40 MILLION VIEWS AND MORE THAN 10 LAKH-->
        <!--                            FOLLOWERS THROUGH ITS FACEBOOK PAGE AND THE YOUTUBE CHANNEL.-->
        <!--                        </h2>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

        <!--        <section class="py-7">-->
        <!--            <div class="container">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-lg-5">-->
        <!--                        <img src="../assets/img/motivation_ashwin2.jpg" class="img-fluid" alt="Doctor">-->
        <!--                    </div>-->
        <!--                    <div class="col-lg-7">-->
        <!--                        <div class="pl-lg-5">-->
        <!--                            <h1 class="font-weight-bold mb-4">AN INSPIRATION TO THE MASSES</h1>-->
        <!--                            <p>Strength India Movement (SIM), is one of the fast growing Indian organisations that-->
        <!--                                believes in empowering people with information around health & general wellness. Founded-->
        <!--                                by orthopedic surgeon Dr.P.R. Ashwin Vijay, Strength India Movement it bringing the-->
        <!--                                right information on healthy lifestyle to people onto their mobile phones and all-->
        <!--                                electronic gadgets.</p>-->

        <!--                            <p>The organization provides information for postures of sleeping,sitting down for longer-->
        <!--                                hours and riding a two wheeler, and these ,when done right,can bring about a huge-->
        <!--                                positive difference in the health and well being. Dr.Ashwin’s information cover a rich-->
        <!--                                gamut of topics including orthopedics, fitness, wellness, healthy living and a lot more-->
        <!--                                in context with health.</p>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

        <!--        <section class="py-7">-->
        <!--            <div class="container">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-lg-5">-->
        <!--                        <img src="../assets/img/motivation_ashwin3.jpg" class="img-fluid" alt="Doctor">-->
        <!--                    </div>-->
        <!--                    <div class="col-lg-7 order-lg-first">-->
        <!--                        <div class="pr-lg-5">-->
        <!--                            <p>Strength India Movement is rapidly growing with over 40 Million views and more than 10-->
        <!--                                lakh followers through its Facebook page and the YouTube channel. The videos also being-->
        <!--                                hugely circulated through other messaging and sharing platforms like WhatsApp, Telegram-->
        <!--                                etc in millions.</p>-->

        <!--                            <p>Strength India Movement has grabbed the attention of a few celebrities who have-->
        <!--                                appreciated the information and shared the videos on social media. Recently SIM has been-->
        <!--                                part of a few events at well reputed schools in India for sensitizing youngsters early-->
        <!--                                in life.</p>-->
        <!--                            <p>In addition to India, SIM has a global outreach with followers from the US, Middle-East-->
        <!--                                and African countries with an organic following more then four hundred thousand.</p>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

    </div>
</template>
<script>

import InnerBanner from '@/views/components/InnerBanner';

export default {
    name: 'Inspiration',
    components: { InnerBanner },
    metaInfo: {
        title: 'Initiatives to combat coronavirus | Dr. Joseph Thomas',
        titleTemplate: '%s - Dr. Joseph Thomas',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: ' The dreadful impact of the pandemic is not unknown to all of us. But also what\n' +
                    'great time than this to give back to society? Dr. Joseph with his active participation in numerous\n' +
                    'volunteering works has helped thousands of people affected by COVID-19.'
            }
        ]
    }
};
</script>

<style scoped>

</style>
