<template>
    <div :class="[image]" class="inner-banner d-flex fl-a-c">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 content text-white">
                    <h1 class="font-weight-bold" :class="['text-' + textColor]" v-if="Heading" v-html="Heading">A GOOD
                        LAUGH AND A LONG SLEEP ARE THE
                        BEST CURES IN THE DOCTOR’S
                        BOOK</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Banner from '../../assets/img/';

export default {
    name: 'InnerBanner',
    data: function () {
        return {
            // imagePath: Banner
        };
    },
    props: {
        image: {
            type: String,
            required: false,
            default: ''
        },
        height: {
            type: String,
            required: false,
            default: 'h-100vh'
        },
        textColor: {
            type: String,
            required: false,
            default: ''
        },
        Heading: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">
.inner-banner {
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    background-position: top;
    min-height: 70vh;
    @media (max-width: 1024px) {
        min-height: 35vh;
        background-position: 25% 76px;
    }
    @media (max-width: 552px) {
        align-items: flex-end;
        padding-bottom: 20px;
        h1 {
            font-size: 2rem;
        }
    }

    //.content {
    //    margin-top: 87px;
    //}
}

.bg-about {
    background-image: url('../../assets/img/about-me.jpg');
}

.bg-social {
    background-image: url('../../assets/img/banner-2.jpg');
}

.bg-covid {
    background-image: url('../../assets/img/banner-3.jpg');
}

.bg-media {
    background-image: url('../../assets/img/banner-4.jpg');
}

//.bg-contact {
//    background-image: linear-gradient(#743152 100%, #743152 100%);
//}

.bg-contact {
    background-image: url('../../assets/img/contact-us.jpg');
}

//.bg-contact {
//    background-image: url('../../assets/img/banner-5.jpg');
//}
</style>
