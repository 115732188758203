<template>
    <div class="home">
        <section>
            <Banner/>
        </section>

        <section class="py-5 py-lg-7">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 mb-4">
                        <div class="pr-lg-5 text-justify c-border-home">
                            <!--                            <h1 class="font-weight-bold mb-4">ORTHOPEDICIAN, ENTREPRENEUR, ICON OF HEALTHCARE, PRIDE OF-->
                            <!--                                TAMIL NADU</h1>-->
                            <p>Dr Joseph Thomas is South India’s leading aesthetic physician who always wears numerous
                                hats for the fulfillment of the mission that he is on. He is a philanthropist, a
                                body-positive enthusiast, social entrepreneur and also a youth role model.
                                <a class="text-decoration-none" href="https://drjosephthomas.in/about/">Dr Joseph
                                    Thomas</a> is the medical director
                                and founder of <a class="text-decoration-none" href="https://www.medlounges.com/">Medlounges</a>,
                                a holistic aesthetic and cosmetic treatment centre.
                            </p>

                            <p class="mb-0 mb-lg-5">He has always stayed at the forefront in delivering his
                                services to underprivileged people. From
                                <a class="text-decoration-none" href="https://drjosephthomas.in/social/">child
                                    education</a>
                                to women and youth empowerment,
                                he has been working with numerous NGOs. Working in the aesthetic sector, Dr Joseph
                                clearly understands the psycho-social battle many have to go through in this society. He
                                has been associated with leading organisations in fighting <a
                                    class="text-decoration-none" href="https://pubmed.ncbi.nlm.nih.gov/32361167/">social
                                    trauma</a> related to mental health problems and body shaming.</p>
                            <!--                            <img src="../assets/img/signature.jpg" class="w-45p my-4" alt="">-->
                            <btn @click="$router.push('/about/')" design="animated-e" border-radius="0"
                                 color="primary"
                                 class="c-btn-1 d-none d-lg-inline-block"
                                 text="ABOUT DR. JOSEPH THOMAS"></btn>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <img src="../assets/img/doctor.jpg" class="img-fluid" alt="Doctor">
                        <div class="d-lg-none mt-4">
                            <btn @click="$router.push('/about/')" design="animated-e" border-radius="0"
                                 color="primary"
                                 class="c-btn-1"
                                 text="ABOUT DR. JOSEPH THOMAS"></btn>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        <section class="bg-quote-home bg-quote-1">-->
        <!--            <div class="container">-->
        <!--                <div class="row quote-content">-->
        <!--                    <div class="col-lg-8 text-center">-->
        <!--                        <h2 class="font-weight-bold mb-0 text-white">-->
        <!--                            NO MATTER WHO YOU ARE, <br class="d-none d-md-block">-->
        <!--                            NO MATTER WHAT YOU DID, <br class="d-none d-md-block">-->
        <!--                            NO MATTER WHERE YOU’VE COME FROM, <br class="d-none d-md-block">-->
        <!--                            YOU CAN ALWAYS CHANGE, <br class="d-none d-md-block">-->
        <!--                            BECOME A BETTER VERSION OF YOURSELF. <br class="d-none d-md-block">-->
        <!--                        </h2>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

        <!--        <section class="py-7">-->
        <!--            <div class="container">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-lg-5">-->
        <!--                        <img src="../assets/img/welcome.jpg" class="img-fluid" alt="Doctor">-->
        <!--                    </div>-->
        <!--                    <div class="col-lg-7">-->
        <!--                        <div class="pr-lg-5">-->
        <!--                            <h1 class="font-weight-bold mb-4">KEYNOTE SPEAKER, LIFE COACH: HOWEVER DIFFICULT LIFE MAY-->
        <!--                                SEEM, THERE IS ALWAYS SOMETHING YOU CAN DO AND SUCCEED AT.</h1>-->
        <!--                            <p>Life may surprise you with challenges and unimaginable difficulties but there is always a-->
        <!--                                way to overcome the obstactles only if you have the will and courage to face it.</p>-->

        <!--                            <p>Dr. Ashwin Vijay has inspired millions of people around the globe with his thought-->
        <!--                                provoking, motivational speeches which is truly inspiring, practical and applicable in-->
        <!--                                our day to day life.</p>-->
        <!--                            <br>-->
        <!--                            <btn design="animated-e" border-radius="0" color="third" class="c-btn-1"-->
        <!--                                 text="MOTIVATION"></btn>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->
        <section class="pb-7">
            <div class="container">
                <div class="row">
                    <CoolLightBox
                        :items="items"
                        :index="index"
                        @close="index = null">
                    </CoolLightBox>

                    <!--                    <div class="c-card">-->
                    <!--                        <img :src="image.thumb" class="bg-media" alt="">-->
                    <!--                        <div class="content">-->
                    <!--                            <h2 class="title text-white">{{ image.title }}</h2>-->
                    <!--                            <p class="copy">{{ image.subtext }}</p>-->
                    <!--                            <btn @click="index = imageIndex" design="animated-e" border-radius="0" color="white"-->
                    <!--                                 class="c-btn-1 mt-3"-->
                    <!--                                 text="View More"></btn>-->
                    <!--                            &lt;!&ndash;                <a class="btn" @click="index = imageIndex">View Trips</a>&ndash;&gt;-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <div class="col-lg-6 mb-4 mb-lg-0" v-for="(image, imageIndex) in items" :key="imageIndex">
                        <a class="d-block p-2 b-2 round-1" style="cursor: pointer" @click="index = imageIndex">
                            <iframe style="pointer-events: none" class="d-flex" width="100%" height="300"
                                    :src="image.src"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!--        <section>-->
        <!--            <div class="container-fluid">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-lg-6 p-0">-->
        <!--                        <img src="../assets/img/merit-left.jpg" class="img-fluid w-100" alt="#">-->
        <!--                    </div>-->
        <!--                    <div class="col-lg-6 p-0">-->
        <!--                        <img src="../assets/img/merit-right.jpg" class="img-fluid w-100" alt="#">-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

        <!--        <section class="py-7">-->
        <!--            <div class="container">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-lg-5">-->
        <!--                        <img src="../assets/img/fitness-expert.jpg" class="img-fluid" alt="Doctor">-->
        <!--                    </div>-->
        <!--                    <div class="col-lg-7 order-lg-first">-->
        <!--                        <div class="pr-lg-5">-->
        <!--                            <h1 class="font-weight-bold mb-4">-->
        <!--                                FITNESS EXPERT: DEDICATE YOURSELF TO BECOMING YOUR BEST-->
        <!--                            </h1>-->
        <!--                            <p>With over two decades of hands-on experience in the fitness world, Dr. Ashwin Vijay is a-->
        <!--                                role model, advisor and an inspiration to many atheletes and fitness enthusiasits around-->
        <!--                                the world.</p>-->

        <!--                            <p>With over 10 million followers, Dr. Ashwin spreads the godness and benefits of being fit-->
        <!--                                through his simple and powerful life changing videos. Dr. Ashwin believes only a strong-->
        <!--                                mind and a fit body can nourish powerful thoughts which is the foundation for a powerful-->
        <!--                                transformation.</p>-->
        <!--                            <br>-->
        <!--                            <btn design="animated-e" border-radius="0" color="third" class="c-btn-1"-->
        <!--                                 text="FITNESS MOTIVATION"></btn>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

    </div>
</template>

<script>

import Banner from '../views/components/Banner';
// import img1 from '@/assets/img/doctor.jpg';

export default {
    name: 'Home',
    components: {
        Banner
    },
    metaInfo: {
        title: 'AESTHETIC PHYSICIAN | BODY POSITIVE ENTHUSIAST | ENTREPRENEUR',
        titleTemplate: '%s - Dr. Joseph Thomas',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Dr. Joseph Thomas is India\'s leading aesthetic physician and a leading healthcare entrepreneur from Kerala. He is the Medical Director and Founder of Medlounges.'
            },
            {
                name: 'keywords',
                content: 'Aesthetic Physician , Cosmetologist, Kerala best cosmetologist , Cosmetologist doctor,Cosmetologist Ernakulam'
            }
        ]
    },
    data: function () {
        return {
            items: [
                {
                    src: 'https://www.youtube.com/embed/Rlui6WLUA_M'
                },
                {
                    src: 'https://www.youtube.com/embed/hSMiAvYAX2g'
                }
            ],
            index: null
        };
    }
};

</script>
<style scoped>
/*@media (max-width: 992px) {*/
/*    .c-border-home {*/
/*        border: 1px solid black;*/
/*        padding: 15px;*/
/*    }*/
/*}*/

</style>
