<template>
    <div>
        <section>
            <InnerBanner image="bg-media" height="h-80vh"/>
        </section>
        <section class="py-5 py-lg-6">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 text-center">
                        <h2 class="font-weight-bold mb-4">MEDIA</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                        <article-card :img="blogImg1"
                                      heading="Medlounges announces new wellness programme ‘Body Shaping and Contouring’"
                                      content="Medlounges. founded by Dr. Joseph Thomas, follows a simple non-invasive body shaping procedure by involving pulsed ultrasound energy to destroy selective fat cells."
                                      link="https://www.thehindubusinessline.com/news/medlounges-announces-new-wellness-programme-body-shaping-and-contouring/article37639011.ece"
                                      source="The Hindu Businessline"
                        ></article-card>
                        <!--                                      content="Medlounges, one of South India’s leading family health and wellness centre, has announced the launch of its unique wellness programme “Body Shaping and Contouring"-->

                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <article-card
                            :img="blogImg2"
                            heading="Medlounges launches wellness programme Body Shaping and Contouring in India"
                            content="Kerala-based Medlounges has launched its unique wellness programme Body Shaping and Contouring in India. The 360° wellness approach has been designed by Dr Joseph Thomas. "
                            link="https://www.biospectrumindia.com/news/77/20023/medlounges-launches-wellness-programme-body-shaping-and-contouring-in-india.html"
                            source="Bio Spectrum"
                        ></article-card>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <article-card
                            :img="blogImg3"
                            heading="Medlounges Unveils Unique Wellness Program “Body Shaping And Contouring” For The First Time In India"
                            content="This 360° wellness approach is specially designed by Dr. Joseph Thomas with the concept of preventive wellness in home environment based on his in-depth study in the areas of aesthetic and anti-ageing"
                            link="https://www.apnnews.com/medlounges-unveils-unique-wellness-program-body-shaping-and-contouring-for-the-first-time-in-india/"
                            source="APN News"
                        ></article-card>
                        <!--                                                    content="360° wellness approach with a collective combination of expertise from family doctor, aesthetic physician, dermatologist and plastic surgeon."-->

                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg4"
                            heading="Open platform for the National Digital Health Ecosystem will be rolled out: Budget 2022"
                            content="Dr Joseph Thomas, CEO, Medlounges said, “It is very welcoming to know about the National Digital Health Ecosystem, which will provide access to citizens’ health care data on a national network. This will speed up any medical processes in the health care system. It will also be an easy transition to a paperless health care system.”"
                            link="https://www.expresshealthcare.in/news/open-platform-for-the-national-digital-health-ecosystem-will-be-rolled-out-budget-2022/433303/"
                            source="Express Healthcare"
                            size="lg"
                        ></article-card>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg5"
                            heading="Male body shaming, a growing reality"
                            content="Dr. Joseph Thomas, a practising aesthetic physician based in Thiruvalla, Kerala, says that 50 per cent of the patients who consult him are men, out of which 70 per cent have been subjected to social or psychological trauma at various points of their life."
                            link="https://www.deccanchronicle.com/lifestyle/health-and-wellbeing/160222/male-body-shaming-a-growing-reality.html"
                            source="Deccan Chronicle"
                            size="lg"
                        ></article-card>
                        <!--                                                    content="It’s a known truth that people are judged by looks. In this age of social media and beauty filters, it is now almost impossible to escape the scrutiny. If an individual doesn’t have the specifications of beauty and desirability, they will be judged and looked down upon"-->

                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg6"
                            heading="Male body shaming: This less-talked about social issue can affect mental health well-being"
                            content="Dr Joseph Thomas, Medical Director of Medlounges, a body positive enthusiast thinks it’s time more men realise they are not alone with this issue. “We always believe that body-shaming is significantly associated with women and rarely with men. But for years, men have also been the silent victim of body shaming in society. From professional athletes to the commoner, body shaming can happen to anyone.“"
                            link="https://economictimes.indiatimes.com/magazines/panache/male-body-shaming-this-less-talked-about-social-issue-can-affect-mental-health-well-being/articleshow/90608560.cms"
                            source="The Economic Times | Panache"
                            size="lg"
                        ></article-card>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg7"
                            heading="Medlounges join hands with CRY"
                            content="Medlounges, founded by Dr. Joseph Thomas, one of India's leading family health and wellness centre in association with Child Rights and You (CRY), an Indian NGO working for child rights in India, conducted a complimentary Medical Camp for the underserved group as part of its Corporate Social Responsibility."
                            link="https://www.dailypioneer.com/2022/state-editions/medlounges-join-hands-with-cry.html"
                            source="The Pioneer"
                        ></article-card>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg8"
                            heading="Domestic travellers and wellness seekers pull Kerala tourism out of the woods"
                            content="“The flow of medical tourists has improved and is nearly back to normal. People from developed countries flock to India mostly for surgical treatment because of a 30-70 percent reduction in cost,” said Dr. Joseph Thomas, founder director of Medlounges, a family health and wellness centre."
                            link="https://www.moneycontrol.com/news/trends/travel-trends/kerala-looks-to-boost-medical-tourism-while-witnessing-steady-rise-in-travel-bookings-8501841.html"
                            source="moneycontrol"
                        ></article-card>
<!--                        content="A surge in domestic arrivals since the beginning of the year has revived Kerala’s hopes-->
<!--                        of shoring up its tourism revenue after two disastrous years that saw a sharp slide in the-->
<!--                        number of travellers to the state due to the COVID-19 pandemic."-->

                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg9"
                            heading="Not Just Celebrities, You Can Fight The Signs Of Ageing Too"
                            content="Some natural anti-ageing suggestions, such as applying sunscreen and consuming a well-balanced diet, can produce results,“ says Dr Joseph Thomas, Medical Director, Medlounges."
                            link="https://www.thehealthsite.com/beauty/skin-care/not-just-celebrities-you-can-fight-the-signs-of-ageing-too-876624/"
                            source="thehealthsite"
                        ></article-card>
<!--                                                    content="In today's day and age, no one wants to look even a year older than their actual age but ageing is a natural process you can't stop. And digitalization is making the process faster and more difficult to control"-->

                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg10"
                            author="Dr Joseph Thomas"
                            heading="Are You Applying Your Sunscreen The Right Way? Here's How To Use It Correctly"
                            content="Sunny days are ideal for taking some activity outside, however, the sun rays may cause serious harm to your skin, notably as you're subjected to them in many situations than you might think every time. Just a brief exposure to the sun's radiation could bring damage to your skin if you don't apply sunscreen."
                            link="https://www.thehealthsite.com/beauty/skin-care/are-you-applying-your-sunscreen-the-right-way-heres-how-to-use-it-correctly-875927/"
                            source="thehealthsite"
                        ></article-card>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-4">
                        <article-card
                            :img="blogImg11"
                            author="Dr Joseph Thomas"
                            heading="UNDERSTANDING BODY SHAMING AND WHY IT OFTEN BEGINS AT HOME"
                            content="Most parents consider it normal to objectify their kids with verbal usages like mottu (fat), kaali (darker), naata (short) in their regular conversations. A child who is exposed to such humiliating remarks from his/her own family after a point tends to start believing in the statements and gradually becomes conscious of his shortfalls."
                            link="https://thedailyguardian.com/understanding-body-shaming-and-why-it-often-begins-at-home/"
                            source="thedailyguardian"
                        ></article-card>
                    </div>
                </div>
            </div>
            <!--            <MediaCard/>-->

        </section>
    </div>
</template>

<script>
// import MediaCard from '../views/components/MediaCard';
import InnerBanner from '../views/components/InnerBanner';
import img1 from '../assets/img/blogs/blog-1.jpg';
import img2 from '../assets/img/blogs/edit/blog-2-21.png';
import img3 from '../assets/img/blogs/edit/blog-31.png';
import img4 from '../assets/img/blogs/blog-4.jpg';
import img5 from '../assets/img/blogs/blog-5.jpeg';
import img6 from '../assets/img/blogs/blog-6-economic-times.webp';
import img7 from '../assets/img/blogs/blog-7.jpg';
import img8 from '../assets/img/blogs/blog-71.webp';
import img9 from '../assets/img/blogs/blog-8.webp';
import img10 from '../assets/img/blogs/blog-9.webp';
import img11 from '../assets/img/blogs/blog-11.jpg';
import ArticleCard from '@/views/components/ui/ArticleCard';

export default {
    name: 'Media',
    metaInfo: {
        title: 'AESTHETIC PHYSICIAN | BODY POSITIVE ENTHUSIAST | ENTREPRENEUR',
        titleTemplate: '%s - Dr. Joseph Thomas',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Dr. Joseph Thomas is India\'s leading aesthetic physician and a leading healthcare entrepreneur from Kerala. He is the Medical Director and Founder of Medlounges.'
            },
            {
                name: 'keywords',
                content: 'Aesthetic Physician , Cosmetologist, Kerala best cosmetologist , Cosmetologist doctor,Cosmetologist Ernakulam'
            }
        ]
    },
    components: { ArticleCard, InnerBanner },
    data () {
        return {
            blogImg1: img1,
            blogImg2: img2,
            blogImg3: img3,
            blogImg4: img4,
            blogImg5: img5,
            blogImg6: img6,
            blogImg7: img7,
            blogImg8: img8,
            blogImg9: img9,
            blogImg10: img10,
            blogImg11: img11
        };
    }
};
</script>

<style scoped>

</style>
