<template>
    <div class="about">
        <section>
            <InnerBanner image="bg-about"/>
        </section>

        <section class="py-5 py-lg-7">
            <div class="container">
                <div class="row text-justify">
                    <div class="col-lg-12 mb-5">
                        <div class="pl-lg-5">
                            <h1 class="font-weight-bold mb-4 text-uppercase">The Journey</h1>
                            <p>Dr Joseph Thomas comes from a family where his mother works as a <a
                                class="text-decoration-none" href="https://drjosephthomas.in/">medical practitioner</a>
                                while his father is an entrepreneur. Seeing his mom work and dedicate her life for the
                                well being of society, Dr Thomas always found himself inclined to the same profile. He
                                decided to pursue aesthetics which was relatively a new branch of medicine in India. His
                                major purpose to choose aesthetics was to recondition his society inside out. aesthetics
                                is not just about cosmetic treatments. It’s a
                                <a class="text-decoration-none"
                                   href="https://www.webmd.com/balance/guide/what-is-holistic-medicine">holistic
                                    treatment</a>
                                that helps people deal
                                with various psycho-social issues as well. Though started as a low scale project, as the
                                year passed by, with Medlounges, his sole goal to make a difference in his fellow beings
                                life has sky-rocketed its way to becoming what it is today. At <a
                                    class="text-decoration-none"
                                    href="https://www.hellomumbainews.com/current-news/marathi-film-industry-goes-south-for-cosmetic-procedures/">Medlounges</a>,
                                all patients
                                experience absolute care and comfort and feel reassured that their problems will be
                                heard and solutions will be identified.</p>

                            <p><a class="text-decoration-none" href="https://drjosephthomas.in/">Dr Thomas</a> aspires
                                to forward his services to uplift the upcoming generation by helping
                                them recognise their superpowers. He is on an expedition to motivate young people
                                by nurturing their skills, building confidence, accelerating positive thoughts,
                                overcoming fear and taking action.</p>
                        </div>
                    </div>
                    <div class="col-lg-12 mb-5">
                        <img src="../assets/img/about-me-detail.jpg" class="img-fluid px-lg-8" alt="Doctor">
                    </div>
                    <div class="col-lg-12 mb-5">
                        <div class="pl-lg-5">
                            <h1 class="font-weight-bold mb-4 text-uppercase">Medical Profile</h1>
                            <p>Dr Joseph Thomas is the medical director and founder of Medlounges. He is an aesthetic
                                physician with a keen eye for facial aesthetics, body sculpting, personalised skin and
                                hair care programmes that have taken him to the forefront in the field of clinical
                                cosmetology and aesthetic medicine. His fellowship training was under Dr Rajesh Vasu,
                                the HOD and consultant plastic surgeon at the Institute of plastic, cosmetic and
                                <a class="text-decoration-none"
                                   href="https://en.wikipedia.org/wiki/Reconstructive_surgery">reconstructive
                                    surgery</a>,
                                Continental Hospitals.
                            </p>

                            <p>Dr Joseph has always had a keen interest in menial invasive methods that helps to enhance
                                the dormant beauty within each individual found. Medlounged with a vision to bring out
                                the inner beauty of each person and is now provided premium services in clinical
                                cosmetology and aesthetics medicine at his centres at Cochin and Thiruvala (Kerala). He
                                was personally trained by Dr Konika Schallen MD, who currently serves as the medical
                                director of the Syneron Candela Institute for Excellence in Boston, USA. He has also
                                done body sculpting training focusing on Liposuction from Sydney under Dr Joseph Ajaka,
                                Medical director of Cosmos clinic, Australia.</p>
                            <p><a class="text-decoration-none"
                                  href="https://www.hellomumbainews.com/current-news/marathi-film-industry-goes-south-for-cosmetic-procedures/">
                                Dr Joseph</a> is also one of the most sought after <a class="text-decoration-none"
                                                                                      href="https://drjosephthomas.in/">aesthetic
                                physicians</a> by celebrities and
                                models who entrust their beauty to him completely. Dr Joseph is a regular participator
                                of the art international conferences on aesthetic and anti-ageing medicine as well as
                                advanced anatomy workshops and master-classes in botox and fillers, keeping abreast of
                                the rapid advances in those speciality fields to ensure the best service of what science
                                and modern medicine can offer. Dr Joseph did his advanced injectable with Dr Maria
                                Angelo Khattar, MD, PhD, Clinical training director of Institute of medical aesthetics,
                                Dubai, UAE, Dr. Joseph is also a prestigious member of the <a
                                    class="text-decoration-none"
                                    href="https://www.a4m.com/ ">American Academy of
                                    anti-ageing medicine</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--        <section class="bg-quote-home bg-quote-2">-->
        <!--            <div class="container">-->
        <!--                <div class="row quote-content">-->
        <!--                    <div class="col-lg-8 text-center">-->
        <!--                        <h2 class="font-weight-bold mb-0 text-white">-->
        <!--                            EAT YOUR FOOD AS MEDICINES<br class="d-none d-md-block">-->
        <!--                            OTHERWISE, YOU HAVE TO EAT<br class="d-none d-md-block">-->
        <!--                            MEDICINES AS YOUR FOOD<br class="d-none d-md-block">-->
        <!--                        </h2>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

        <!--        <section class="py-7">-->
        <!--            <div class="container">-->
        <!--                <div class="row">-->
        <!--                    <div class="col-lg-5">-->
        <!--                        <img src="../assets/img/doctor2.jpg" class="img-fluid" alt="Doctor">-->
        <!--                    </div>-->
        <!--                    <div class="col-lg-7 order-lg-first">-->
        <!--                        <div class="pr-lg-5">-->
        <!--                            <h1 class="font-weight-bold mb-4">RENOWN, LUMINARY, ICON IN THE INDUSTRY</h1>-->
        <!--                            <p>As a person, Dr. Ashwin is much more than just an orthopedic. Along with his busy and-->
        <!--                                successful career as an orthopaedician, Dr. Ashwin also envisioned and brought to life a-->
        <!--                                platform called STRENGTH INDIA MOVEMENT that brings simple, yet very rich information-->
        <!--                                about healthy lifestyles to people.</p>-->

        <!--                            <p>He also won the ICON OF HEALTHCARE AWARDS by Berg in SINGAPORE 2016 for that organisation-->
        <!--                                and PRIDE OF TAMIL NADU Award 2017 By Round Table India.</p>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </section>-->

    </div>
</template>
<script>

import InnerBanner from '@/views/components/InnerBanner';

export default {
    name: 'About',
    components: { InnerBanner },
    metaInfo: {
        title: 'Dr. Joseph Thomas| Medical director and founder of Medlounges',
        titleTemplate: '%s - Dr. Joseph Thomas',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Dr. Joseph Thomas is the medical director and founder of Medlounges. He is an\n' +
                    'aesthetic physician with a keen eye for facial aesthetics, body sculpting, personalized skin, and hair\n' +
                    'care programs that have taken him to the forefront in the field of clinical cosmetology and aesthetic\n' +
                    'medicine.'
            }
        ]
    }

};
</script>

<style scoped>

</style>
