<template>
    <div class="about">

        <section>
            <InnerBanner image="bg-social" height="h-80vh"/>
        </section>
        2

        <section class="py-5 py-lg-6">
            <div class="container">
                <div class="row text-justify">
                    <div class="col-lg-12">
                        <div class="pl-lg-5">
                            <h1 class="font-weight-bold mb-4 mb-lg-5 text-uppercase">Social Initiatives</h1>
                            <ul class="list-unstyled">
                                <li class="mb-4">
                                    <h4 class="mb-3">
                                        <a class="text-decoration-none"
                                           href="https://en.wikipedia.org/wiki/Women%27s_empowerment">
                                            Women Empowerment
                                        </a>
                                    </h4>
                                    <p>
                                        Dr Joseph has taken various initiatives in creating awareness among the society
                                        on women empowerment. He believes that building gender imparity is not only good
                                        for our entire womanhood but also for the entire nation too.
                                    </p>
                                </li>
                                <li class="mb-4">
                                    <h4 class="mb-3">Child Education</h4>
                                    <p>
                                        <a class="text-decoration-none" href="https://drjosephthomas.in/">Dr Joseph</a>
                                        has been supporting NGOs in his homeland who ensure all children have
                                        the access to education regardless of gender and social status. His work has
                                        helped numerous underprivileged children with a free education that will help
                                        them live a life of respect.
                                    </p>
                                </li>
                                <li class="mb-4">
                                    <h4 class="mb-3">Child Welfare</h4>
                                    <p>
                                        He supports a private initiative for child welfare that ensures the
                                        <a class="text-decoration-none"
                                           href="https://en.wikipedia.org/wiki/Holistic_education">
                                            holistic development of children
                                        </a>
                                        and youth, helping them strive in a safe and sound environment.
                                    </p>
                                </li>
                                <li>
                                    <h4 class="mb-3">Youth Empowerment</h4>
                                    <p>
                                        He has been supporting NGOs in helping our upcoming generation pave their way to
                                        lead a successful life. From confidence building to improving the quality of
                                        life, he has encouraged youth to take charge of their lives.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>

import InnerBanner from '@/views/components/InnerBanner';

export default {
    name: 'About',
    components: { InnerBanner },
    metaInfo: {
        title: 'Social initiatives | Dr. Joseph Thomas',
        titleTemplate: '%s - Dr. Joseph Thomas',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Dr. Joseph has taken various initiatives in creating awareness among the society\n' +
                    'on women empowerment, holistic development, children education programs, Child Welfare, Youth\n' +
                    'Empowerment.'
            }
        ]
    }
};
</script>

<style scoped>

</style>
